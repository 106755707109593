
.slider-swiper .swiper {
    display:flex;
    justify-content: center;
}
.slider-swiper .swiper .swiper-wrapper {
    flex-basis:content;
}


